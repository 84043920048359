<template>
<v-container fluid>
  <v-row>
    <v-col>
      <v-card class="pa-5">
        <div class="text-center text-h4">Add Debits</div>
        <v-form ref='debit_form'>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="12" lg="6">
                <v-alert type="error" v-if="errResp">{{errResp}}</v-alert>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" lg="3">
                <v-select v-model="formData.people_id" :items="peopleList" label="Person"></v-select>
              </v-col>
              <v-col cols="12" lg="3">
                <v-text-field v-model="formData.amount" label="Debit Amount" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" lg="3">
                <v-select v-model="formData.type" :items="amountStatus" label="Amount Status"></v-select>
              </v-col>
              <v-col cols="12" lg="3">
                <v-select v-model="formData.currency" :items="currency" label="Currency Type"></v-select>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" lg="3">
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="formData.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.time"
                      label="Time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="formData.time"
                    full-width
                    @click:minute="$refs.menu.save(formData.time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="3">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" lg="6">
                 <v-textarea v-model="formData.description"  label="Description" rows="3"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" lg="6" class="text-center">
                <v-btn color="primary" class="px-10" @click="addDebit()" :disabled="loading">Add</v-btn><br>
                <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="loading"
                    ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-overlay :value="getLoading" color="white">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import {get_all_people} from '@api/people'
import {add_debit} from '@api/debits'
export default {
  data(){
    return {
      menu:false,
      menu2:false,
      formData:{
        people_id:null,
        description:"",
        time:`${(new Date()).getHours()<10?'0':''}${(new Date()).getHours()}:${(new Date()).getMinutes()<10?'0':''}${(new Date()).getMinutes()}`,
        date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        type:"",
        amount:"",
        currency:"",
      },
      amountStatus:[
        { 
          text: 'Borrowed',
          value: 'borrowed',
        },
        { 
          text:"Received",
          value: 'received',
        }
      ],
      currency:[
        {
          text: 'PKR - Pakistan Rupees',
          value: 'pkr'
        },
        {
          text: "SAR - Saudi Riyal",
          value: 'riyal'
        },
        {
          text: "OMR - Omani Riyal",
          value: 'omr'
        },
        {
          text: "Pound",
          value: 'pound'
        }
      ],
      peopleList:[],
      getLoading:false,
      loading:false,
      errResp:''
    }
  },
  mounted(){
    this.getAllPeople();
  },
  methods: {
    getAllPeople(){
      this.getLoading= true;
      this.errResp='';
      get_all_people().then(resp=>{
        this.getLoading= false;
        if(resp.status ==='Success'){
          this.setPeopleList(resp.data)
        }else{
          this.errResp=resp.errors;
        }
      })
    },
    setPeopleList(data){
      let list=[]
      data.forEach((p) => {
        var obj={}
          obj['text']=p.name,
          obj['value']=p.id
        list.push(obj); 
      });
      this.peopleList=list;
    },
    addDebit(){
      this.loading= true;
      this.errResp = '';
      add_debit(this.formData)
      .then(resp=>{
        this.loading= false;
        if(resp.status == 'Success'){
          this.$toastr.s(resp.message);
          this.reset();
        }else{
          this.errResp=resp.errors;  
        }
      })
    },
    reset(){
      this.formData={
        user_id:null,
        description:"",
        time:`${(new Date()).getHours()<10?'0':''}${(new Date()).getHours()}:${(new Date()).getMinutes()<10?'0':''}${(new Date()).getMinutes()}`,
        date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        type:"",
        amount:"",
        currency:"",
      }
    }
  }
}
</script>

<style>

</style>